<template>
  <AppPanel subtitle="Listagem de questionários">
    <template #content>
      <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/questionarios/new"
        editPath="/questionarios/"
        :expander="false"
        subtitle="Questionário"
        :extraActionItems="actionItems"
        @onToggleMenu="onToggleMenu"
        tooltip="Cadastro Questionário"
        nomeTelaDoManual="questionarios-list"
       >
        <template #columns>
            <Column field="nome" header="Nome" :sortable="true" sortField="nome"></Column>
            <Column field="tipo" header="Tipo" :sortable="true" sortField="tipo">
                <template #body="slotProps">
                    {{
                        slotProps.data.tipo === 'Q' ? "Questionário de qualidade de vida" :
                        slotProps.data.tipo === 'A' ? "Pré-Anamsene" :
                        ""}}
                </template>
            </Column>
        </template>
      </AppPaginatedGrid>
    </template>
  </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '../../services/http';

export default {
    data() {
        return {
          service: null,
          record: {},
          actionItems: [
            {
                label: 'Enviar e-mail',
                icon: 'pi pi-envelope',
                command: () => this.sendMail(this.record)
            }]
        };
    },
    created() {
        this.service = new BaseService('/questionario');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        async sendMail(record) {
            try {
                await getClientBase().post(`/questionario/enviar-email/${record.id}`);
                this.$toast.add({
                    severity: 'success',
                    summary: 'E-mail enviado com sucesso',
                    life: 3000
                });
            } catch (error) {
                const message = error?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: message,
                    life: 3000
                });
            }
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
    }
}
</script>